.logiout-panel {
  width: 540px;
  margin: auto !important;
  padding: 0px !important;
  margin-top: 10% !important;
}

.logiout-panel .icon-cisco {
  color: var(--cui-accent-color);
}
