.new-user-wrap {
  border-right: var(--cui-border);
}
.pl-4 {
  padding-left: 15px;
}

.pr-4 {
  padding-right: 15px;
}
.register-message-wrap {
  /* margin-top: 45px; */
}

.cui .header-panels .header-panel a.header-item {
  font-size: 22px;
}

@media (min-width: 768px) {
  .user-register-form {
    width: 75%;
    margin: 0 auto;
  }
}
.cui .content {
  min-height: calc(101vh - 77px);
}
.company-form-panel {
  min-height: 360px;
}
.company-list-wrap {
  min-height: 317px;
  max-height: 317px;
  overflow: auto;
  border: var(--cui-border);
}
.organization-form-wrap {
  min-height: 137px;
  max-height: 137px;
  overflow: auto;
  border: var(--cui-border);
}
.place-holder-box {
  padding: 15px;
  width: 100%;
  /* border: var(--cui-border); */
  text-align: center;
  /* background-color: var(--cui-border-color); */
  border: 1px dotted var(--cui-color-gray-600);
}
.place-holder-box .place-holder-title {
  color: var(--cui-color-gray-600);
}
.center-holder-wrap {
  height: 100%;
  width: 100%;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

@media only screen and (min-width: 768px) {
  .cui .col--bordered-right {
    border-right: var(--cui-border);
  }
}
